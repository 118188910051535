const watch = require('./orbit/font-watcher.js').default;

window.bbcuser.isUKCombined().then((isUKCombined) => {
  // Do this first as it's worth doing whether the guard clause below kicks in or not
  if (!isUKCombined) {
    const logoLink = document.getElementById('homepage-link');
    if (logoLink) {
      const ukUrl = logoLink.getAttribute('href');
      const intUrl = ukUrl.replace('.bbc.co.uk', '.bbc.com');
      logoLink.setAttribute('href', intUrl);
    }
  }

  // If the user has already opened the non-JS more drawer, do not bother
  // geo-tagging the header nor footer as it will just confuse things and
  // the `display: none` changes this might cause might even crash
  // screenreaders as elements disappear while focused
  // This might seem overkill for the footer, but halting that too means
  // both the (non-JS) more menu and the footer will show the domestic links
  // consistently.
  if (window.location.hash === '#orbit-more-drawer') {
    return;
  }

  const className = isUKCombined ? 'orbit-domestic-yes' : 'orbit-domestic-no';
  ['orb-banner', 'orbit-more-drawer', 'orb-footer'].forEach((elementId) => {
    const el = document.getElementById(elementId);
    if (el) {
      el.classList.add(className);
    }
  });
});

Promise.all([
  window.bbcpage.isModal(),
  watch('ReithSans', 'b-reith-sans-loaded'),
]).then((results) => {
  // TODO remove when legacy orb goes away
  if (
    // if in legacy orbit
    window.require.defined &&
    window.require.defined('orb/features/masthead/_layoutprimarynav')
  ) {
    const hasHeader = !results[0];
    if (hasHeader) {
      window.require(['orb/features/masthead/_layoutprimarynav'], (layout) =>
        layout.recalculateLayout()
      );
    }
  }
});

if (document.documentElement.className.includes('b-reith-serif-font')) {
  watch('ReithSans', 'b-reith-serif-loaded');
}
